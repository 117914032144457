import {
  createRouter,
  // createWebHashHistory,
  RouteRecordRaw,
  createWebHistory,
} from "vue-router";
import { createStore } from '@/vuex/store';
import { ElMessage } from "element-plus";
// import { getRouterName } from '@/utils/utils';
import authMenu from '@/common/menuList.json';

// import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "portal",
    redirect: "/portal",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/portal/pLayout_new.vue"),
    children: [
      {
        path: "/portal",
        name: "portal",
        component: () => import("@/views/portal/components/portalPage.vue"),
      },
    ],
  },
  {
    path: "/portalNew",
    name: "portalNew",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/portal/pLayout_new.vue"),
    children: [
      {
        path: "/portalNew",
        name: "portalNew",
        component: () => import("@/views/portal/pLayout_new.vue"),
      },
    ],
  },
  {
    path: "/home",
    name: "home",
    redirect: "/home",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/HomeView.vue"),
    children: [
      {
        path: "/home",
        name: "HomeAll",
        component: () => import("@/views/HomeAll.vue"),
      },
      {
        path: "/square",
        name: "square",
        component: () => import("@/views/Square.vue"),
      },
      {
        path: "/app/:code",
        name: "app",
        component: () => import("@/views/ChatView.vue"),
      },
      {
        path: "/paint",
        name: "paintPic",
        component: () => import("@/views/PaintPic.vue"),
      },
    ],
  },
  {
    path: "/console",
    name: "console",
    redirect: "/console/factory",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/console/ConsoleView.vue"),
    children: [
      {
        path: "/console/factory",
        name: "myApp",

        component: () => import("@/views/console/MyApp.vue"),
      },
      {
        path: "/console/interface",
        name: "openInterface",
        component: () => import("@/views/console/AppInterface/OpenInterface.vue"),
      },
      {
        path: "/console/dataAnalysis",
        name: "dataAnalysis",

        component: () => import("@/views/console/DataAnalysis.vue"),
      },

      {
        path: "/console/createApp",
        name: "createApp",
        component: () => import("@/views/console/CreateApp.vue"),
      },
      {
        path: "/console/agent",
        name: "EmbeddingApp",
        component: () =>
          import("@/views/console/EmbeddingApp/index.vue"),
      },

      {
        path: "/console/account",
        name: "myCenter",

        component: () => import("@/views/console/myCenter/MyCenter.vue"),
      },


      {
        path: "/console/chatManage",
        name: "chatManage",
        component: () => import("@/views/console/ChatManage.vue"),
      },
      // {
      //   path: "/console/ChatIphone",
      //   name: "chatManage",
      //   component: () => import("@/views/console/ChatIphone.vue"),
      // },
      {
        path: "/console/clients",
        name: "clients",
        component: () => import("@/views/console/Clients/ClientsList.vue"),
      },
      {
        path: "/console/dataAnalysis",
        name: "dataAnalysis",
        component: () => import("@/views/console/DataAnalysis/index.vue"),
      },
      {
        path: "/console/clients/configClient",
        name: "configurationClient",
        component: () => import("@/views/console/Clients/ConfigurationClient.vue"),
      },
      {
        path: "/console/clients/config",
        name: "configurationClientChat",
        component: () => import("@/views/console/Clients/ConfigurationClientChat.vue"),
      },
      {
        path: "/console/knowledgeBase",
        name: "knowledgeBase",
        component: () => import("@/views/console/KnowledgeBase/KnowledgeBase.vue"),
      },
      {
        path: "/console/knowledgeBase/knowledgeManage",
        name: "KnowledgeManage",

        component: () => import("@/views/console/KnowledgeBase/KnowledgeManage.vue"),
        redirect: "/console/knowledgeBase/baseAll",
        children: [{
          path: "/console/knowledgeBase/baseAll",
          name: "baseAll",

          component: () => import("@/views/console/KnowledgeBase/BaseAll.vue"),
        },
        {
          path: "/console/knowledgeBase/baseFile",
          name: "baseFile",

          component: () => import("@/views/console/KnowledgeBase/BaseFile.vue"),
        },
        {
          path: "/console/knowledgeBase/importFile",
          name: "importFile",

          component: () => import("@/views/console/KnowledgeBase/ImportFile.vue"),
        },
        {
          path: "/console/knowledgeBase/retrievalFile",
          name: "retrievalFile",

          component: () => import("@/views/console/KnowledgeBase/RetrievalFile.vue"),
        },
        {
          path: "/console/knowledgeBase/baseSetting",
          name: "baseSetting",

          component: () => import("@/views/console/KnowledgeBase/BaseSetting.vue"),
        }, {
          path: "/console/knowledgeBase/mediaResource",
          name: "mediaResource",

          component: () => import("@/views/console/KnowledgeBase/MediaResource.vue"),
        }]
      },
      {
        path: "/console/plugins",
        name: "plugins",
        component: () => import("@/views/console/PluginAll.vue"),
      },
      {
        path: "/console/workflows",
        name: "flow",
        component: () => import("@/views/console/FlowWork.vue"),
      },
      {
        path: "/console/plugins/detail",
        name: "customPlugin",
        component: () => import("@/views/console/PLuginManage/CustomPlugin.vue"),
      },
      {
        path: "/console/teamSpace",
        name: "teamSpace",
        component: () => import("@/views/console/TeamSpace/index.vue"),
      },
      {
        path: "/console/teamSpace/roleEdit",
        name: "roleEdit",
        component: () => import("@/views/console/TeamSpace/RightTree.vue"),
      },
      {
        path: "/console/price",
        name: "price",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/RechargeView.vue"),
      },
      {
        path: "/console/customManage",
        name: "customManage",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/console/CustomManage/index.vue"),
        redirect: "/console/customManage/customerList",
        children: [{
          path: "/console/customManage/customerList",
          name: "customerList",
          component: () => import("@/views/console/CustomManage/compoents/CustomerList.vue"),
        }, {
          path: "/console/customManage/tagList",
          name: "tagList",
          component: () => import("@/views/console/CustomManage/compoents/TagList.vue"),
        }]
      },
    ],
  },
  {
    path: '/pluginKey',
    name: "pluginKey",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/pluginKey.vue"),
  },
  {
    path: '/console/workflow',
    name: "addFlow",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/vueFlow/VueFlow.vue"),
  },
  {
    path: '/benefits',
    name: "inviteActivi",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/InviteActivi.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/ChatView.vue"),
  },
  {
    path: "/web/linkAI/:clientId",
    name: "web",
    component: () =>
      import("@/views/console/ibotManage/iframeChat.vue"),
  },
  {
    path: "/web/iframe/:clientId",
    name: "fullweb",
    component: () =>
      import("@/views/console/ibotManage/iframeFullChat.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    //访问主页的时候 重定向到index页面
    redirect: "/404",
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/Not404.vue"),
  },
  {
    path: "/iframe",
    name: "iframes",
    component: () => import("@/views/console/ibotManage/iframeTest.vue"),
  }
];
//router/index.js

const router = createRouter({
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 }
  },
  history: createWebHistory(),
  routes,
});


function findMenuByPath(menuList: any, path: any): any {
  for (const item of menuList) {
    // 检查当前项的路径
    if (item.path === path) {
      return item; // 找到匹配项
    }
    // 如果有子菜单，递归查找
    if (item.children) {
      const found = findMenuByPath(item.children, path);
      if (found) {
        return item; // 返回父菜单
      }
    }
  }
  return null; // 如果没有找到，返回 null
}
router.beforeEach((to: any, from, next) => {

  const store = createStore()
  const userDetail = store.state.user

  if (userDetail.authorizedRoute) {
    const allow = findMenuByPath(authMenu, to.path)
    try {
      if (allow.isAuthen && allow.isAuthen === 'Y') {
        const go = userDetail.authorizedRouteList.includes(allow.path)
        if (go) {
          next();
        } else {
          // ElMessage({
          //   type: 'warning',
          //   message: '暂无权限'
          // })
          next({ path: userDetail.authorizedRouteList[0] });
          return
        }
      } else {
        next()
      }
    } catch {
      next()
    }


    // console.log(7, userDetail.authorizedRouteList)
    // const a = getRouterName(userDetail.authorizedRouteList)

  } else {
    if (to.name === 'clients' && store.state.userPrivige.clientManage < 1) {
      ElMessage({
        type: 'warning',
        message: '暂无权限'
      })
      return
    }
    if (to.name === 'chatManage' && store.state.userPrivige.customerManage < 1) {
      ElMessage({
        type: 'warning',
        message: '暂无权限'
      })
      return
    }
    if (to.name === 'teamSpace' && store.state.userPrivige.memberNumber < 1) {
      ElMessage({
        type: 'warning',
        message: '暂无权限'
      })
      return
    }
    next();
  }

});
export default router;
