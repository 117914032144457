export const NodeTypes = {
    START: { type: 'start', label: "开始" },
    END: { type: 'end', label: "结束" },
    INTENT_BRANCH: { type: 'intent_branch', label: "意图分支", nodeType: 'branch', desc: '通过意图识别输入的内容，判断后续流程分支' },
    LOGIC_BRANCH: { type: 'logic_branch', label: "逻辑分支", nodeType: 'branch', desc: '逻辑判断输入变量的值以选择后续流程分支' },
    FIXED_REPLY: { type: 'fixed_reply', label: "固定回复", nodeType: 'individuality', desc: '输出设置的固定内容' },
    LLM: { type: 'llm', label: "大模型", nodeType: 'basics', desc: '调用大语言模型，并结合系统提示词生成回复' },
    KNOWLEDGE_BASE: { type: 'knowledge_base', label: "知识库", nodeType: 'basics', desc: '选取特定知识库，根据输入内容检索知识库并输出' },
    APP: { type: 'app', label: "应用", nodeType: 'basics', desc: '使用已创建的轻应用或知识库应用生成内容' },
    PLUGIN: { type: 'plugin', label: "插件", nodeType: 'basics', desc: '使用内置插件或自定义插件执行任务' },
    // CODE: { type: 'code', label: "代码", nodeType: 'basics',desc: '编写代码以处理输入内容并生成返回值' },
    // DATABASE: { type: 'database', label: "数据库", nodeType: 'basics',desc: '对数据库进行读写操作' },
    CHANNEL_SEND: { type: 'channel_send', label: "渠道发送", nodeType: 'individuality', desc: '将内容发送至所选取的接入渠道' },
    MANUAL: { type: 'manual', label: "转人工", nodeType: 'individuality', desc: '直接执行对话“转人工”并发送提醒' },

};

export function convertObjectToArrayDirectly(obj: any) {
    const result = Object.values(obj); // 直接获取对象的所有值
    return result;
}

export const slideList = [
    {
        type: 'start',
        label: '开始',
    },
    {
        type: 'end',
        label: '结束',
    },
    {
        type: 'intent_branch',
        label: '意图分支',
    },
    {
        type: 'logic_branch',
        label: '逻辑分支',
    },
    {
        type: 'fixed_reply',
        label: '固定回复',
    },
    {
        type: 'llm',
        label: '大模型',
    },
    {
        type: 'knowledge_base',
        label: '知识库',
    },
    {
        type: 'app',
        label: '应用',
    },
    {
        type: 'plugin',
        label: '插件',
    },
    // {
    //     type: 'code',
    //     label: '代码',
    // },
    // {
    //     type: 'database',
    //     label: '数据库',
    // },
    {
        type: 'manual',
        label: '转人工',
    },
    {
        type: 'channel_send',
        label: '渠道发送',
    },
];